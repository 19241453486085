<template>
  <div class="accounts-content">
    <a-row>
      <a-col :span="24">
        <h4><a-icon type="container" /> Счета</h4>
      </a-col>
    </a-row>
    <a-card class="mt-2">
      <a-table :columns="transColumns" :dataSource="transData" :pagination="true">
        <span slot="status" slot-scope="status, record">
          <span v-if="status === 0"><a-tag color="red">Не выставлен</a-tag></span>
          <a-tooltip title="Ожидает оплаты" placement="bottom">
            <span v-if="status === 1" class="font-size-18">
              <font-awesome-icon icon="money-check" class="text-gray-4" />
            </span>
          </a-tooltip>
          <a-tooltip title="Оплачен" placement="bottom">
            <span v-if="status === 2" class="font-size-18" >
              <font-awesome-icon icon="money-check" class="text-success" />
            </span>
          </a-tooltip>
          <a-tooltip title="Отменен" placement="bottom">
            <span v-if="status === 3" class="font-size-18">
              <font-awesome-icon icon="money-check" class="text-danger" />
            </span>
          </a-tooltip>
          <a-tooltip title="Просрочен" placement="bottom">
            <span v-if="status === 4" class="font-size-18">
              <font-awesome-icon icon="money-check" class="text-danger" />
            </span>
          </a-tooltip>
          <a-tooltip v-if="record.transferred" title="Выведен" placement="bottom">
            <span class="font-size-18 ml-2">
              <font-awesome-icon icon="external-link-square-alt" class="text-success" />
            </span>
          </a-tooltip>
          <a-tooltip v-else title="Не выведен" placement="bottom">
            <span class="font-size-18 ml-2">
              <font-awesome-icon icon="external-link-square-alt" class="text-gray-4" />
            </span>
          </a-tooltip>
        </span>
        <span slot="created_at" slot-scope="created_at">
          <a-icon type="calendar" /> {{ created_at }}
        </span>
        <span slot="bill_id" slot-scope="bill_id, record">
          <a :href="record.bill_url" target="_blank">{{ bill_id }}</a>
        </span>
        <span slot="amount" slot-scope="amount">
          <span>{{ amount }} ₽</span>
        </span>
        <span slot="transferred" slot-scope="transferred">
          <span v-if="transferred">{{ transferred.amount }} ₽</span>
        </span>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import moment from 'moment-timezone'
// import userProfile from '@/views/apps/users/profile.vue'

const transColumns = [
  {
    title: '№ счета',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Аккаунт',
    dataIndex: 'account_name',
    key: 'account_name',
    scopedSlots: { customRender: 'account_name' },
  },
  {
    title: 'Дата и время',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'ID счета',
    dataIndex: 'bill_id',
    key: 'bill_id',
    scopedSlots: { customRender: 'bill_id' },
  },
  {
    title: 'Логин',
    dataIndex: 'login',
    key: 'login',
    scopedSlots: { customRender: 'login' },
  },
  {
    title: 'Сумма',
    dataIndex: 'amount',
    key: 'amount',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Выведено',
    dataIndex: 'transferred',
    key: 'transferred',
    scopedSlots: { customRender: 'transferred' },
  },
  // {
  //   title: 'Комиссия',
  //   dataIndex: 'сomm',
  //   key: 'сomm',
  //   // scopedSlots: { customRender: 'сomm' },
  // },
  // {
  //   title: 'В кошелек',
  //   dataIndex: 'income',
  //   key: 'income',
  //   scopedSlots: { customRender: 'income' },
  // },
  {
    title: 'Статус',
    dataIndex: 'status',
    // width: '25%',
    scopedSlots: { customRender: 'status' },
  },
]

export default {
  name: 'index',
  props: ['accountId'],
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      transData: [],
      transColumns,
      moment,
    }
  },
  mounted () {
    this.getTransactions(this.accountId)
  },
  methods: {
    getTransactions(accId) {
      const url = '/admin/accounts/' + accId + '/transactions'
      apiClient.get(url).then((response) => {
        this.transData = response.data.data
      }).catch(error => { console.log(error) })
    },
  },
}
</script>

<style scoped>

</style>
